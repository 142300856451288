<template src="./scheduled.html"></template>

<script>
import mixins from "../../../../mixins/mixins.js";
import moment from "moment";
export default {
  mixins: [mixins],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      showWebTable: true,
      scheduleds: [],
      activeUserImg: "",
      selected: [],
      dateSelected: "",
      cancelFee: 0,
      popupExam: false,
      showWebButtons: true,
      disabledButtonInit: false,
      toRerenderWebTable: 0,
      toRerenderMobileTable: 0,
      numPages: 0,
      currentx: 1,
      pagination: false,
      searchedWord: "",
      showValueOnCancellation: false,
    };
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    const vm = this;
    vm.init();
  },
  watch: {
    currentx: function () {
      const vm = this;
      vm.handleChangePage();
    },
  },
  computed: {
    isProject() {
      return this.$session.get("isProject") ? this.$session.get("isProject") : false;
    },
  },
  methods: {
    init() {
      const vm = this;
      vm.getScheduled();
    },
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width <= 1000) {
        vm.showWebTable = false;
        vm.showWebButtons = false;
      } else {
        vm.showWebTable = true;
        vm.showWebButtons = true;
      }
    },
    handleSearch(searching) {
      const vm = this;
      vm.searchedWord = searching;
      vm.currentx = 1;
      setTimeout(() => {
        vm.init();
      }, 1000);
    },
    handleChangePage() {
      const vm = this
      vm.getScheduled();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateList(){
      const vm = this;
      vm.searchedWord = "";
      vm.currentx = 1;
      if (vm.toRerenderWebTable === 0) vm.toRerenderWebTable += 1;
      else vm.toRerenderWebTable -= 1;
      if (vm.toRerenderMobileTable === 0) vm.toRerenderMobileTable += 1;
      else vm.toRerenderMobileTable -= 1;
      vm.openLoading();
      vm.init();
    },
    openLoading(){
      const vm = this;
      vm.$vs.loading();
      setTimeout( ()=> {
        vm.$vs.loading.close();
      }, 500);
    },
    getScheduled() {
      const vm = this;
      let page = 0;
      if (vm.currentx == 1) {
        page = 0;
      } else {
        if (vm.currentx > 1) {
          page = vm.currentx - 1;
        }
      }
      vm.axios
        .get(vm.$store.state.filooServer + "appointments?page=" +
            page +
            "&search=" +
            vm.searchedWord +
            "&size=10&sort=dateHour,desc", {
          headers: {
            Authorization: vm.$session.get("bearer"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.scheduleds = response.data.content;
            vm.numPages = response.data.totalPages;
            vm.pagination = vm.numPages > 1;
            for (const s in vm.scheduleds) {
              vm.scheduleds[s].doctorName = vm.testNullUndefinedEmpty(
                vm.scheduleds[s].doctor
              )
                ? "---------"
                : vm.scheduleds[s].doctor.name;
              vm.scheduleds[s].specialtyName = vm.testNullUndefinedEmpty(
                vm.scheduleds[s].specialty
              )
                ? "---------"
                : vm.scheduleds[s].specialty.name;
            }
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter e listar as consultas agendadas",
            6000
          );
          console.error({
            error: error,
            endpoint: "appointments",
            method: "getScheduled",
            http_method: "GET",
          });
        });
    },
    handleSelected(tr) {
      const vm = this;
      vm.selected = tr;
      vm.selected.dayOfWeek = vm.formatDayOfWeek(tr.dateHour);
      if (vm.testNullUndefinedEmpty(vm.selected.clinic.address.street)) {
        vm.selected.clinicStreet = "";
      } else {
        vm.selected.clinicStreet = vm.selected.clinic.address.street;
      }
      if (vm.testNullUndefinedEmpty(vm.selected.clinic.address.number)) {
        vm.selected.clinicNumber = "";
      } else {
        vm.selected.clinicNumber = vm.selected.clinic.address.number;
      }
      if (vm.testNullUndefinedEmpty(vm.selected.clinic.address.city)) {
        vm.selected.clinicCity = "";
      } else {
        vm.selected.clinicCity = vm.selected.clinic.address.city;
      }
      if (vm.testNullUndefinedEmpty(vm.selected.clinic.address.state)) {
        vm.selected.clinicState = "";
      } else {
        vm.selected.clinicState = vm.selected.clinic.address.state;
      }
      if (vm.testNullUndefinedEmpty(vm.selected.clinic.address.zipcode)) {
        vm.selected.clinicZipcode = "";
      } else {
        vm.selected.clinicZipcode = vm.selected.clinic.address.zipcode;
      }
      if (tr.dateHour) {
        const now = moment().format("L");
        const dateParts = tr.dateHour.split(" ");
        const momentDaySelected = moment(vm.dateToEN(dateParts[0])).format("L");
        if (momentDaySelected < now) {
          vm.disabledButtonInit = true;
        } else {
          vm.disabledButtonInit = false;
        }
      }

      if (tr.type === "SCHEDULE") {
        vm.$bvModal.hide("modal-schedule-exam");
        vm.$bvModal.show("modal-attendance-schedule");
      } else {
        vm.$bvModal.hide("modal-attendance-schedule");
        vm.popupExam = true;
        vm.$bvModal.show("modal-schedule-exam");
      }
      vm.getClinicPhoto(vm.selected.clinic.id);
    },
    dateToEN(date) {
      return date.split("/").reverse().join("-");
    },
    getDay(dateHour) {
      return dateHour.substring(0, 10);
    },
    getHour(dateHour) {
      return dateHour.substring(12, 16);
    },
    getDayOfWeek(date) {
      date = date.substring(1, 10);
      var dayOfWeek = new Date(date).getDay();
      return isNaN(dayOfWeek)
        ? null
        : [
          "Domingo",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
        ][dayOfWeek];
    },
    startRTC() {
      const vm = this;
      new Promise((resolve) => {
        vm.$bvModal.hide("modal-attendance-schedule");
        resolve();
      })
        .then(() => {
          vm.$bvModal.show("modal-attendance-tips");
        })
        .catch(() => {
          console.log("errrooo");
        });
    },
    goToWaitingRoom() {
      const vm = this;
      new Promise((resolve) => {
        vm.$bvModal.hide("modal-attendance-tips");
        resolve();
      })
        .then(() => {
          vm.$router.push({
            path: "/cam-test-room/" + vm.selected.medicalRecordId,
            //query: { selected: vm.selected },
          });
        })
        .catch(() => {
          console.log("errrooo");
        });
    },
    verifyCancelation() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "appointments/" +
            vm.selected.id +
            "/verify-cancellation",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.$bvModal.hide("modal-attendance-schedule");
            vm.openConfirm(response.data.value);
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível verificar o pedido de cancelamento de consulta"
          );
          console.error({
            endpoint: "appointments/" + vm.selected.id + "/verify-cancellation",
            http_method: "GET",
            method: "verifyCancelation",
            error: error,
          });
        });
    },
    cancel() {
      const vm = this;
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "appointments/" +
            vm.selected.id +
            "/cancel",
          "",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            vm.showNotifySuccess(
              "Operação realizada com sucesso!",
              "Sua consulta foi cancelada.",
              4000
            );
            vm.$bvModal.hide("modal-confirm-cancellation");
            vm.$store.commit(
              "UPDATE_CUSTOMER_BALANCE",
              !vm.$store.state.updatedCustomerBalance
            );
            vm.init();
          } else {
            vm.showNotifyError("Não foi possível cancelar a consulta");
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível enviar o pedido de cancelamento de consulta"
          );
          console.error({
            endpoint: "appointments/" + vm.selected.id + "/cancel",
            http_method: "PUT",
            method: "cancel",
            error: error,
          });
        });
    },
    noCancel() {
      const vm = this;
      vm.$bvModal.show("modal-attendance-schedule");
      console.log("Nao cancelando agendamento");
    },
    openConfirmExam() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirmar",
        text: "Deseja cancelar estes exames?",
        accept: this.cancelExam,
        acceptText: "Sim",
        cancelText: "Não",
        cancel: this.noCancelExam,
      });
    },
    openConfirm(value) {
      const vm = this;
      vm.showValueOnCancellation = !vm.isProject && value > 0;
      vm.cancelFee = vm.testNullUndefinedEmpty(value)
        ? vm.maskMoney(0)
        : vm.maskMoney(value);
      vm.$bvModal.show("modal-confirm-cancellation");
      setTimeout(function () {
        const button = document.getElementById("buttonNo");
        button.focus();
      }, 1);
    },
    cancelExam() {
      const vm = this;
      console.log("Cancelando exame...");
      this.axios
        .put(
          vm.$store.state.filooServer +
            "appointments/" +
            vm.selected.id +
            "/cancel",
          "",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            vm.$swal("Sucesso!", "Exame cancelado", "success");
          } else {
            vm.$swal("Erro ao cancelar", "error");
          }
        })
        .catch(function (error) {
          console.log(error);
          vm.$swal("Erro ao cancelar", "error");
        });
    },
    noCancelExam() {
      const vm = this;
      vm.$bvModal.show("modal-schedule-exam");
    },
    getClinicPhoto(id) {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "webclinics/photo/download/" + id,
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          })
        .then( response => {
          if (response.status === 200) {
            vm.testNullUndefinedEmpty(response.data) ?
              vm.$session.set("clinicPhoto", null) :
              vm.$session.set("clinicPhoto", `data:image/png;base64,${ response.data}`);
          }
        })
        .catch( error => {
          console.error({
            error: error,
            endpoint: "webclinics/photo/download/" + id,
            method: "getClinicPhoto",
            http_method: "GET",
          });
        });
    },
  },
};
</script>

<style lang="scss" src="./scheduled.scss"></style>
